import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Post from '../../../components/post';
import B from './button';
export const _frontmatter = {
  "title": "PactFi Adds Degen to Aeneas Rewards",
  "date": "04/12/2022"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Post frontmatter={props.pageContext.frontmatter} mdxType="Post">
      <p>{`Degen has been included in the `}<a parentName="p" {...{
          "href": "https://pact.fi"
        }}>{`PactFi`}</a>{` Community Aeneas Rewards. The 10,000 Algo reward pool will be paid out to liquidity providers on the Degen / Algo 1% liquidity pool.`}</p>
      <br />
      <p>{`Starting Friday, April 15th liquidity providers will receive Aeneas Rewards, the program will run for seven weeks.`}</p>
      <br />
      <p><strong parentName="p">{`How to add liquidity`}</strong></p>
      <br />
      <ol>
        <li parentName="ol">{`First, you'll need Degen, if you don't have any click here to buy some on PactFi.`}</li>
      </ol>
      <B title='Buy Degen' link='https://app.pact.fi/swap?pair=ALGO0/DEGEN+TOKEN417708610(100)' mdxType="B" />
      <br />
      <br />
      <ol {...{
        "start": 2
      }}>
        <li parentName="ol">{`Second, you'll add Degen and Algo to the Degen/Algo 1% pool.`}</li>
      </ol>
      <B title='Add Liquidity' link='https://app.pact.fi/add-liquidity/677288079' mdxType="B" />
      <br />
      <br />
      <ol {...{
        "start": 3
      }}>
        <li parentName="ol">{`That's it! Rewards will show up in the PactFi interface, nice work!`}</li>
      </ol>
    </Post>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      